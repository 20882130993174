<template>
  <div class="flex flex-col h-full w-full">
    <RsHeader :is-loading="isLoading" />

    <div class="flex-1">
      <template v-if="isLoading">
        <RsGlobalLoading />
      </template>

      <slot />
    </div>

    <RsFooter />
    <RsModalContainer />
  </div>
</template>

<script setup lang="ts">
import useLoading from '~/composables/useLoading'
import RsModalContainer from '~/components/core/RsModalContainer.vue'
import RsGlobalLoading from '~/components/common/RsGlobalLoading.vue'
import RsHeader from '~/components/core/RsHeader.vue';
import RsFooter from '~/components/core/RsFooter.vue';

const { isLoading } = useLoading()
</script>
